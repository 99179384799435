import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Data, Result } from 'react-spreadsheet-import/types/types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chunk, max } from 'lodash';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatDuration, intervalToDuration } from 'date-fns';
import { nl } from 'date-fns/locale';
import {
  Asset, PropertyInput,
  useBulkCreateRegisteredSensorAssetMutation, useGetRegisteredSensorSchemeQuery,
} from '../../../generated/gql/types';
import { WeCityOrganizationIdHeader } from '../../../Global';
import { RegisteredSensorAssetProperty, validationStringToOptionList } from '../../../AssetHelpers';
import Loader from '../../Loader';

function ImportSpreadsheet() {
  const isOpen = true;
  const { t } = useTranslation();
  const params = useParams();
  const [importStatus, setImportStatus] = useState<ImportStatus>({
    importResults: [],
    totalRows: 0,
  });
  const {
    data: schemeData,
    loading: schemaLoading,
  } = useGetRegisteredSensorSchemeQuery({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });
  const fields = [
    {
      label: 'Naam',
      key: 'name',
      alternateMatches: ['sensor name', 'sensor'],
      fieldType: {
        type: 'input',
      },
      example: 'RV25janA',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Name is required',
          level: 'error',
        },
      ],
    },
    // leaving this with comments for WIP
    {
      // Visible in table header and when matching columns.
      label: 'Doel',
      // This is the key used for this field when we call onSubmit.
      key: RegisteredSensorAssetProperty.Goal,
      // Allows for better automatic column matching. Optional.
      alternateMatches: ['goal', 'sensor goal'],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - 'input' / 'checkbox' / 'select'.
        type: 'input',
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: 'Geen doel',
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be 'required' / 'unique' / 'regex'
          rule: 'required',
          errorMessage: 'Goal is required',
          // There can be 'info' / 'warning' / 'error' levels. Optional. Default 'error'.
          level: 'error',
        },
      ],
    },
    {
      label: 'Type',
      key: RegisteredSensorAssetProperty.SensorType,
      alternateMatches: ['type'],
      fieldType: {
        type: 'select', // if automatic option matching is available, this can be changed to select
        options:
          validationStringToOptionList(
            schemeData?.scheme?.data?.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.SensorType)?.validation ?? '',
            t,
          ).map((option) => (
            { label: option.name, value: option.value }
          )),
      },
      example: 'camera',
    },
    {
      label: 'Thema',
      key: RegisteredSensorAssetProperty.Theme,
      alternateMatches: ['theme'],
      fieldType: {
        type: 'select', // if automatic option matching is available, this can be changed to select
        options:
          validationStringToOptionList(
            schemeData?.scheme?.data?.propertyTypes?.find((p) => p.name === RegisteredSensorAssetProperty.Theme)?.validation ?? '',
            t,
          ).map((option) => (
            { label: option.name, value: option.value }
          )),
      },
      example: 'auto',
    },
    {
      label: 'Latitude',
      key: 'latitude',
      alternateMatches: ['lt', 'location'],
      fieldType: {
        type: 'input',
      },
      example: '52.51177',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Latitude is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Longitude',
      key: 'longitude',
      alternateMatches: ['ld', 'location'],
      fieldType: {
        type: 'input',
      },
      example: '5.478521',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Longitude is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Geldig tot',
      key: RegisteredSensorAssetProperty.ValidUntil,
      alternateMatches: ['Valid until', 'Geldig tot'],
      fieldType: {
        type: 'input',
      },
      example: '31-11-2025',
      validations: [
        {
          rule: 'regex',
          value: '^[0-3][0-9]-[0-1][0-9]-20[0-9]{2}$',
          errorMessage: 'Correct validity date format is: dd-mm-yyyy',
          level: 'error',
        },
      ],
    },
    {
      label: 'Referentie',
      key: RegisteredSensorAssetProperty.SensorReferenceId,
      alternateMatches: ['reference', 'Referentie'],
      fieldType: {
        type: 'input',
      },
      example: 'REF123456-0.1',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Reference is required for spreadsheet upload',
          level: 'error',
        },
      ],
    },
    {
      label: 'verwerkt persoonsgegevens',
      key: RegisteredSensorAssetProperty.ProcessesPersonalData,
      alternateMatches: ['verwerkt persoonsgegevens', 'processes personal data', 'personaldara', 'persoonsgegevens'],
      fieldType: {
        type: 'checkbox',
        booleanMatches: { ja: true, nee: false },
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'This field is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Verplaatsbare sensor',
      key: RegisteredSensorAssetProperty.IsMobile,
      alternateMatches: ['verplaatsbare sensor', 'moveable', 'mobile sensor'],
      fieldType: {
        type: 'checkbox',
        booleanMatches: { verplaatsbaar: true, vast: false },
      },
      example: '',
      validations: [
        {
          rule: 'required',
          errorMessage: 'This field is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Organisatie',
      key: RegisteredSensorAssetProperty.Organization,
      alternateMatches: ['Organisatie', 'Organization', 'Organisation'],
      fieldType: {
        type: 'input',
      },
      example: 'WeCity',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Organisation is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'Contactpersoon',
      key: RegisteredSensorAssetProperty.ContactName,
      alternateMatches: ['Contactpersoon', 'contactperson', 'contact'],
      fieldType: {
        type: 'input',
      },
      example: 'Jan Janssen',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Contactperson is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'E-mail',
      key: RegisteredSensorAssetProperty.ContactEmail,
      alternateMatches: ['E-mail', 'email'],
      fieldType: {
        type: 'input',
      },
      example: 'a@b.c',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Email is required',
          level: 'error',
        },
      ],
    },
    {
      label: 'wettelijke grondslag',
      key: RegisteredSensorAssetProperty.LegislationUrl,
      alternateMatches: ['wettelijke grondslag', 'legal basis'],
      fieldType: {
        type: 'input',
      },
      example: 'http://legal.wecity.nl',
      validations: [
        {
          rule: 'regex',
          errorMessage: '^+*$',
          level: 'info',
        },
      ],
    },
  ] as const;

  const [bulkCreateAssets] = useBulkCreateRegisteredSensorAssetMutation({
    context: {
      clientName: 'asset',
      headers: {
        [WeCityOrganizationIdHeader]: params.orgId,
      },
    },
  });

  // status must be import 'Import (nieuw)' - 'importnew'
  const onSubmit = (data: Result<string>, _: File) => {
    setImportStatus({ importStarted: new Date(), totalRows: data.validData.length, importResults: [] });

    Promise.all(chunk(data.validData, 50).map((batch) => (
      bulkCreateAssets({
        variables: {
          command: batch.map((row) => (
            {
              name: row?.name?.toString() ?? '',
              properties: [
                ...Object.entries(row)
                  .filter(([k, v], ___) => !['name', 'longitude', 'latitude'].includes(k.toString()) && v != null)
                  .map(([k, v], ___) => (
                    {
                      type: k,
                      key: k,
                      value: v,
                    })),
                {
                  type: RegisteredSensorAssetProperty.Coordinates,
                  key: RegisteredSensorAssetProperty.Coordinates,
                  value: `${row?.latitude},${row?.longitude}`,
                },
                {
                  type: RegisteredSensorAssetProperty.Status,
                  key: RegisteredSensorAssetProperty.Status,
                  value: 'importnew',
                },
              ] as PropertyInput[],
            }
          )),
        },
      }).then((result) => {
        const bulkCreateResponse = result.data?.bulkCreateRegisteredSensorAssets?.data;
        if (bulkCreateResponse !== null && bulkCreateResponse !== undefined && bulkCreateResponse.length > 0) {
          setImportStatus((prevState) => ({
            ...prevState,
            importResults: [...prevState.importResults, ...[{
              importedRows: bulkCreateResponse as Asset[],
              failedRows: [],
            }]],
          }));
        }
      }).catch((errorResult) => {
        setImportStatus((prevState) => ({
          ...prevState,
          importResults: [...prevState.importResults, ...[{
            importedRows: [],
            failedRows: batch,
            errorMessage: errorResult,
          }]],
        }));
      })
    ))).then((___) => {
      setImportStatus((prevState) => ({
        ...prevState,
        importCompleted: new Date(),
      }));
    });
  };
  const navigate = useNavigate();

  function getProgressionPercentage() {
    const importedCount = importStatus.importResults.reduce((previousValue, currentValue) => previousValue + currentValue.importedRows.length, 0);
    const totalRows = max([importStatus?.totalRows ?? 1, 1]) ?? 1;

    return (max([(importedCount / totalRows) * 100, 10]));
  }

  if (schemaLoading) {
    return (<Loader />);
  }

  function shouldClose() {
    // TODO: Implement
  }

  return (
    <>
      <Modal
        show={importStatus.importStarted !== undefined}
        size="lg"
        popup
        className="!z-[9999]"
        onClose={() => navigate(-1)}
      >
        <Modal.Header />

        <Modal.Body>
          <div className="text-center">
            {importStatus.importResults.filter((r) => r.errorMessage !== undefined).length > 0 ? (
              <FontAwesomeIcon
                color="#ff0000"
                icon={icon({ name: 'exclamation-circle', family: 'classic', style: 'solid' })}
                size="3x"
              />
            ) : (
              <FontAwesomeIcon
                icon={icon({ name: 'info-circle', family: 'classic', style: 'solid' })}
                size="3x"
                color="#0E5272"
              />
            )}

            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              {importStatus.importStarted !== undefined && importStatus.importCompleted !== undefined ? (
                <div className="font-bold">
                  Het importeren is voltooid, in
                  {' '}
                  {formatDuration(intervalToDuration({
                    start: importStatus.importStarted,
                    end: importStatus.importCompleted,
                  }), { locale: nl })}
                </div>
              ) : (
                <>
                  <div className="font-bold pb-4">
                    {' '}
                    De sensoren worden geimporteerd in het sensorregister, sluit dit paneel niet
                  </div>
                  <Loader />
                </>
              )}
            </h3>
            <div className="flex flex-col justify-center gap-4">
              <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div
                  className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                  style={{ width: `${getProgressionPercentage()}%` }}
                >
                  {importStatus.importResults.reduce((previousValue, currentValue) => previousValue + currentValue.importedRows.length, 0)}
                  /
                  {importStatus.totalRows}
                </div>
              </div>

              <div>
                {importStatus.importResults.filter((r) => r.errorMessage !== undefined).length > 0 && (
                  <div className="text-red-600 font-bold">Tijdens het importeren zijn er fouten opgetreden:</div>
                )}
                {importStatus.importResults.filter((r) => r.errorMessage !== undefined).map(((result) => (
                  <div key={`error-${Math.random() * 999999999}`} className="text-red-600">
                    {result.errorMessage?.toString()}
                  </div>
                )))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={() => shouldClose()}
        onSubmit={onSubmit}
        fields={fields}
      />
    </>
  );
}

type ImportResult = {
  importedRows: Asset[]
  failedRows: Data<string>[]
  errorMessage?: string | undefined
};

type ImportStatus = {
  importStarted?: Date | undefined
  importCompleted?: Date | undefined
  importResults: ImportResult[]
  totalRows: number
};

export default ImportSpreadsheet;
